class MonthlyPayments {
  constructor(conventionalProduct, intelligentProduct, administrationCosts, insuranceRates) {
    this.conventionalProduct = conventionalProduct;
    this.intelligentProduct = intelligentProduct;
    this.administrationCosts = administrationCosts;
    this.insuranceRates = insuranceRates;
    this.defaultConfig = { maxRate: 0.02, vfmgRatio: 0.4 };
    this.defaultInsuranceRate = 0;
    this.defaultAdministrationCost = 0;
    this.tax = 0.008;
  }

  product(intelligent) {
    return intelligent ? this.intelligentProduct : this.conventionalProduct;
  }

  amountValidConfigs(amount, downPayment, term, intelligent) {
    const toFinance = this.toFinance(amount, downPayment, term, intelligent);

    return this.product(intelligent).configurations
      .filter(c => c.minAmount <= toFinance && c.maxAmount >= toFinance);
  }

  configuration(amount, downPayment, term, intelligent) {
    const config = this.amountValidConfigs(amount, downPayment, term, intelligent)
      .find(c => c.minTerm <= term && c.maxTerm >= term);

    return config || this.defaultConfig;
  }

  administrationCost(amount, downPayment, term, intelligent) {
    const difference = amount - downPayment;
    const duration = intelligent ? term + 1 : term;
    const cost = this.administrationCosts
      .filter(c => c.minAmount <= difference && c.maxAmount >= difference)
      .find(c => c.minTerm <= duration && c.maxTerm >= duration);

    return (cost && cost.value) || this.defaultAdministrationCost;
  }

  insuranceRate(term, intelligent) {
    const duration = intelligent ? term + 1 : term;
    const rate = this.insuranceRates
      .find(i => i.term === duration);

    return (rate && rate.value) || this.defaultInsuranceRate;
  }

  toFinance(amount, downPayment, term, intelligent) {
    const difference = amount - downPayment;

    return (difference + this.administrationCost(amount, downPayment, term, intelligent)) /
      (1 - this.insuranceRate(term, intelligent) - this.tax);
  }

  crf(amount, downPayment, term, intelligent) {
    const maxRate = this.configuration(amount, downPayment, term, intelligent).maxRate;

    return ((1 + maxRate) ** term * maxRate) / ((1 + maxRate) ** term - 1);
  }

  conventionalMonthlyPayment(amount, downPayment, term) {
    const monthlyPayment = this.toFinance(amount, downPayment, term, false) *
      this.crf(amount, downPayment, term, false);

    return isFinite(monthlyPayment) && monthlyPayment > 0 ? Math.round(monthlyPayment) : 0;
  }

  vfmg(amount, downPayment, term, intelligent) {
    const vfmgRatio = this.configuration(amount, downPayment, term, intelligent).vfmgRatio;

    return intelligent ? (vfmgRatio * amount) : 0;
  }

  intelligentMonthlyPayment(amount, downPayment, term) {
    const toFinance = this.toFinance(amount, downPayment, term, true);
    const presentValue = toFinance - this.vfmg(amount, downPayment, term, true) /
      ((1 + this.configuration(amount, downPayment, term, true).maxRate) ** (term + 1));
    const monthlyPayment = presentValue * this.crf(amount, downPayment, term, true);

    return isFinite(monthlyPayment) && monthlyPayment > 0 ? Math.round(monthlyPayment) : 0;
  }
}

export default MonthlyPayments;
